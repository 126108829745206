<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="modelName" class="ml-3 mb-3" slider-color="primary" show-arrows>
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.resourcetype.tabs.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#dynamicsattributes'" v-if="!isCreatingElement && hasAttributeFunctionality">{{
					$t('form.resourcetype.tabs.dynamicsattributes')
				}}</v-tab>
				<v-tab :key="2" :href="'#resourcetab'" v-if="!isCreatingElement && hasResourceFunctionality">{{
					$t('form.resourcetype.tabs.resourcetab')
				}}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="modelName">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<v-row dense>
								<v-col cols="6">
									<pui-text-field
										:id="`name-${modelName}`"
										v-model="model.name"
										:label="$t('form.resourcetype.name')"
										required
										toplabel
										maxlength="50"
									></pui-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="10">
									<pui-field-set :title="$t('form.resourcetype.associatedentities')">
										<pui-multi-select
											itemValue="id"
											itemText="label"
											v-model="model.entities"
											:items="allEntities"
											:itemsToSelect="selected_entities"
											:disabled="formDisabled"
											reactive
										>
										</pui-multi-select>
									</pui-field-set>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" lazy :value="'dynamicsattributes'" v-if="!isCreatingElement && hasAttributeFunctionality">
					<pui-master-detail
						componentName="attributegrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ resourcetypeid: 'resourcetypeid' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" lazy :value="'resourcetab'" v-if="!isCreatingElement && hasResourceFunctionality">
					<pui-master-detail
						componentName="resourcesgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ resourcetypeid: 'resourcetypeid' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'resourcetype-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'resourcetype',
			portauthorities: [],
			portsauthoritiesKey: 0,
			allEntities: [
				{
					label: this.$t('form.resourcetype.movements'),
					id: 'M'
				},
				{
					label: this.$t('form.resourcetype.operations'),
					id: 'O'
				},
				{
					label: this.$t('form.resourcetype.stops'),
					id: 'A'
				},
				{
					label: this.$t('form.resourcetype.services'),
					id: 'S'
				}
			],
			entities: []
		};
	},
	methods: {
		afterGetData() {
			if (this.model.portauthorityid) {
				this.model.portauthorities.push(this.model.portauthorityid);
			}
		}
	},
	computed: {
		selected_entities() {
			if (!this.model.associatedentities) {
				return null;
			}
			return this.model.associatedentities.split(',');
		},
		hasResourceFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_RESOURCES');
		},
		hasAttributeFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_ATTRIBUTE');
		}
	},
	created() {}
};
</script>
